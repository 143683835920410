<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="800px"
    :before-close="handleClose"
    destroy-on-close
  >
    <template #title>
      <h3 class="dialog-title">Import {{ importType }}</h3>
    </template>

    <!-- Upload Section (when no docs uploaded yet) -->
    <div v-if="documents.length === 0" class="upload-section">
      <p class="upload-text">Drop a file here or click to upload</p>
      <el-upload
        action="#"
        class="upload-demo"
        drag
        :file-list="fileList"
        :on-change="handleFileChange"
        :auto-upload="false"
        :limit="1"
      >
        <i class="el-icon-upload upload-icon"></i>
        <div class="el-upload__text"><em>Click to upload</em> or drag and drop a file here</div>
      </el-upload>

      <!-- Enter Password -->
      <el-input
        v-model="keyImport"
        placeholder="Enter password"
        prefix-icon="el-icon-lock"
        type="password"
        class="search-input"
        clearable
      >
        <template #append>
          <el-button
            type="primary"
            @click="importDocument"
            :loading="isLoading"
            :disabled="isLoading"
          >
            Unlock File
          </el-button>
        </template>
      </el-input>
    </div>

    <!-- Document Selection Section -->
    <div v-else class="persona-selection">
      <h3 style="display: block; margin-top: 0px; margin-bottom: 15px">
        Select {{ importType }} to import
      </h3>
      <!-- Search and Select All -->
      <div class="search-section" style="display: flex; gap: 10px; margin-bottom: 15px">
        <el-input
          v-model="searchQuery"
          placeholder="Search documents..."
          prefix-icon="el-icon-search"
          class="search-input"
          clearable
          size="small"
          style="flex-grow: 1"
        />
      </div>

      <!-- Document List -->
      <div>
        <el-table
          :data="filteredDocuments"
          @selection-change="handleSelectionChange"
          style="width: 100%; max-height: 300px; overflow-y: auto; margin-bottom: 20px"
          border
        >
          <!-- Checkbox selection column -->
          <el-table-column type="selection" width="55" reserve-selection> </el-table-column>

          <!-- Name column -->
          <el-table-column
            prop="document_name"
            label="Name"
            sortable
            :sort-method="caseInsensitiveSort"
          >
            <template #default="{ row }">
              {{ row.document_name || row.title }}
            </template>
          </el-table-column>

          <!-- Created At column -->
          <el-table-column prop="createdAt" label="Created At" sortable width="200">
            <template #default="{ row }">
              {{ formatDate(row.createdAt) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- Footer -->
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="handleClose" type="danger" :loading="isLoading" :disabled="isLoading">
          Cancel
        </el-button>
        <el-button
          type="primary"
          @click="handleConfirm"
          :disabled="!selectedDocuments.length || isLoading"
          :loading="isLoading"
        >
          Import ({{ selectedDocuments.length }})
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "ExportDialog",
  props: ["isLoading", "dialogVisible", "importType"],
  data() {
    return {
      fileList: [],
      selectedDocuments: [],
      searchQuery: "",
      areAllSelected: false,
      documents: [],
      keyImport: "",
      uploadedFile: null,
      algo: {
        name: "AES-GCM",
        iv: new TextEncoder("utf-8").encode("keyreply"),
      },
      sortOption: "name",
    };
  },
  methods: {
    handleConfirm() {
      const selectedDocuments = this.documents.filter((d) => this.selectedDocuments.includes(d.id));
      this.$emit("import", selectedDocuments);
    },
    handleFileChange(file, fileList) {
      console.log("handle file change");
      this.uploadedFile = file;
      return false;
    },
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
    handleResetState() {
      this.fileList = [];
      this.selectedDocuments = [];
      this.documents = [];
    },
    toggleSelectAll() {
      if (this.selectedDocuments.length === this.documents.length) {
        this.selectedDocuments = [];
      } else {
        this.selectedDocuments = this.documents.map((d) => d.id);
      }
      this.areAllSelected = !this.areAllSelected;
    },
    handleUpload() {
      console.log(this.fileList);
    },
    async importDocument() {
      if (!this.keyImport) {
        this.$notify.error({
          title: "Password Error",
          position: "bottom-right",
          message: "Invalid password",
        });

        return;
      }
      if (!this.uploadedFile) {
        this.$notify.error({
          title: "File Error",
          position: "bottom-right",
          message: "No file uploaded.",
        });

        return;
      }

      try {
        const key = await this.importPrivateKey(this.keyImport);
        var fr = new FileReader();

        fr.readAsArrayBuffer(this.uploadedFile.raw);
        const cipherBuffer = await new Promise((resolve) => {
          fr.onloadend = (event) => {
            resolve(event.target.result);
          };
        });
        const clearBuffer = await crypto.subtle.decrypt(this.algo, key, cipherBuffer);
        const clearJSON = new TextDecoder("utf-8").decode(clearBuffer);

        const importData = JSON.parse(clearJSON);
        if (importData.exportType !== this.importType) {
          this.$notify.error({
            title: "File Error",
            position: "bottom-right",
            message: "Invalid file type.",
          });
          return;
        }
        this.documents = importData.data;
        console.log(this.documents);
      } catch (error) {
        console.error(error.message);
        this.$notify.error({
          title: "Decryption Error",
          position: "bottom-right",
          message: "Please check the file and password.",
        });
      }
    },
    async importPrivateKey(keyImportObj) {
      return await window.crypto.subtle.importKey(
        "jwk",
        {
          alg: "A256GCM",
          ext: true,
          k: keyImportObj,
          key_ops: ["decrypt"],
          kty: "oct",
        },
        {
          name: "AES-GCM",
          length: 256,
        },
        false,
        ["decrypt"]
      );
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handleSelectionChange(selectedRows) {
      this.selectedDocuments = selectedRows.map((row) => row.id);
    },
    caseInsensitiveSort(a, b) {
      const nameA = (a.document_name || a.title || "").toLowerCase();
      const nameB = (b.document_name || b.title || "").toLowerCase();
      return nameA.localeCompare(nameB);
    },
  },
  computed: {
    filteredDocuments() {
      if (this.sortOption === "name") {
        this.documents.sort((a, b) =>
          this.importType === "documents"
            ? a.document_name.toLowerCase().localeCompare(b.document_name.toLowerCase())
            : a.title.toLowerCase().localeCompare(b.title.toLowerCase())
        );
      } else if (this.sortOption === "date") {
        this.documents.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      }
      switch (this.importType) {
        case "documents":
          return this.documents.filter((d) =>
            d.document_name.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        default:
          return this.documents.filter((d) =>
            d.title.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
      }
    },
  },
};
</script>

<style scoped>
.custom-dialog {
  border-radius: 8px;
}

.upload-section {
  text-align: center;
  padding: 20px;
}

.upload-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.upload-demo {
  width: 100%;
  border-radius: 8px;
}

.upload-icon {
  font-size: 24px;
  color: #409eff;
}

.persona-selection {
  padding: 10px 20px;
}

.selection-text {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.persona-checkbox {
  font-size: 14px;
  margin: 5px 0;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.dialog-footer .el-button {
  min-width: 100px;
}

.dialog-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.upload-section {
  text-align: center;
  padding: 20px 0;
}

.selection-text {
  font-weight: 500;
  margin-bottom: 10px;
}

.search-input {
  width: 100%;
}
</style>

<style>
.el-upload * {
  margin-bottom: 0 !important;
}
</style>

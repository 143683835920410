<template>
  <div id="gen-ai-wrapper">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Documents" name="documents"> <Documents /> </el-tab-pane>
      <el-tab-pane label="Articles" name="articles"> <Articles /> </el-tab-pane>
      <el-tab-pane label="Websites" name="website"> <Websites /> </el-tab-pane>
      <el-tab-pane label="External Source" name="external_source"> <ExternalSource /> </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Documents from "./Documents/Index.vue";
import Articles from "./Articles/Index.vue";
import ExternalSource from "./ExternalSource/Index.vue";
import Websites from "./Websites/Index.vue";
export default {
  components: { Documents, Articles, Websites, ExternalSource },
  data() {
    return {
      activeTab: "documents",
    };
  },
  mounted() {
    // this.$store.dispatch("GENAI_GET_MODULES");
  },
};
</script>

<style>
.export-list-wrapper {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #dcdfe6;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 15px;
}

.export-list-item {
  width: 100%;
  margin-bottom: 10px;
  margin-left: 0 !important;
}

.dialog-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.el-message-box__title {
  font-size: 24px !important;
  text-transform: capitalize;
}
</style>

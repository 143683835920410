<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="Export Personas"
    width="800px"
    :destroy-on-close="true"
  >
    <div class="export-dialog">
      <!-- Search bar for filtering personas -->
      <div style="display: flex; gap: 10px; align-items: center; margin-bottom: 15px">
        <el-input v-model="searchQuery" placeholder="Search personas..." clearable />
      </div>

      <!-- Multiple select checkboxes persona -->
      <p class="selection-text">Select personas to export</p>
      <el-table
        :data="filteredPersonas"
        @selection-change="handleSelectionChange"
        style="width: 100%; max-height: 300px; overflow-y: auto"
        border
      >
        <!-- Checkbox column -->
        <el-table-column type="selection" width="55" reserve-selection> </el-table-column>

        <!-- Name column -->
        <el-table-column prop="name" label="Name" sortable :sort-method="caseInsensitiveSort">
        </el-table-column>

        <!-- Created At column -->
        <el-table-column prop="createdAt" label="Created At" width="200" sortable>
          <template #default="{ row }">
            {{ formattedDate(row.createdAt) }}
          </template>
        </el-table-column>
      </el-table>

      <el-input
        :value="keyExport.k"
        placeholder="Generated Password"
        type="text"
        disabled
        style="margin: 20px 0"
      >
        <template #append>
          <el-button type="primary" @click="copyKey(keyExport)"> Copy </el-button>
        </template>
      </el-input>

      <!-- Footer actions -->
      <div class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="handleExport" :disabled="!selectedPersonas.length">
          Export ({{ selectedPersonas.length }})
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "ExportDialog",
  props: ["personas"],
  data() {
    return {
      dialogVisible: false,
      selectedPersonas: [],
      searchQuery: "",
      keyExport: {},

      algo: {
        name: "AES-GCM",
        iv: new TextEncoder("utf-8").encode("keyreply"),
      },
    };
  },
  computed: {
    filteredPersonas() {
      return this.personas.filter((persona) =>
        persona.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    areAllSelected() {
      return this.selectedPersonas.length === this.filteredPersonas.length;
    },
  },
  methods: {
    async handleExport() {
      const selectedPersonas = this.personas.filter((persona) =>
        this.selectedPersonas.includes(persona.id)
      );
      const { key, cipherBuffer } = await this.encrypt(
        JSON.stringify({
          data: selectedPersonas,
          exportType: "persona",
        })
      );
      this.keyExport = await window.crypto.subtle.exportKey("jwk", key);
      this.copyKey(this.keyExport);
      this.downloadFile(cipherBuffer);
    },
    handleResetState() {
      this.selectedPersonas = [];
      this.searchQuery = "";
    },
    handleClose() {
      this.dialogVisible = false;
      this.handleResetState();
    },
    toggleSelectAll() {
      if (this.areAllSelected) {
        this.selectedPersonas = [];
      } else {
        this.selectedPersonas = this.filteredPersonas.map((persona) => persona.id);
      }
    },
    async encrypt(plaintext) {
      const textEncoder = new TextEncoder("utf-8");
      const algoKeyGen = {
        name: "AES-GCM",
        length: 256,
      };
      const key = await window.crypto.subtle.generateKey(algoKeyGen, true, ["encrypt"]);
      const data = textEncoder.encode(plaintext);
      const cipherBuffer = await crypto.subtle.encrypt(this.algo, key, data);
      this.keyExport = await crypto.subtle.exportKey("jwk", key);
      return { key, cipherBuffer };
    },
    copyKey(keyExportObj) {
      if (keyExportObj) {
        const clip = document.createElement("input");
        document.body.appendChild(clip);
        clip.setAttribute("value", keyExportObj.k);
        clip.select();
        document.execCommand("copy");
        document.body.removeChild(clip);

        this.$message({
          message: "Successfully copied export password to the clipboard.",
          type: "success",
        });
      } else {
        this.$message({
          message: "No key detected. Please export again.",
          type: "error",
        });
      }
    },
    downloadFile(cipherBuffer) {
      const blob = new Blob([cipherBuffer], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const timestamp = moment().format("YYYYMMDD_HHmm");
      a.href = url;
      a.download = `persona_export_${timestamp}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
    caseInsensitiveSort(a, b) {
      const nameA = (a.name || "").toLowerCase();
      const nameB = (b.name || "").toLowerCase();
      return nameA.localeCompare(nameB);
    },
    handleSelectionChange(selectedRows) {
      this.selectedPersonas = selectedRows.map((row) => row.id);
    },
    formattedDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.export-dialog {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.persona-card {
  border: 1px solid #ebeef5;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: none;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}
</style>

<template>
  <div>
    <el-dialog
      width="800px"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      destroy-on-close
    >
      <template #title>
        <h3 class="dialog-title">Export {{ exportType }}</h3>
      </template>

      <div style="padding: 10px">
        <h3 style="display: block; margin-top: 0px; margin-bottom: 15px">
          Select {{ exportType }} to export
        </h3>

        <!-- Search -->
        <div style="display: flex; gap: 10px; margin-bottom: 20px">
          <el-input v-model="search" placeholder="Search documents"></el-input>
        </div>

        <!-- Document List -->
        <el-table
          :data="filterDocuments"
          @selection-change="handleSelectionChange"
          style="width: 100%; max-height: 300px; overflow-y: auto; margin-bottom: 20px"
          border
        >
          <!-- Checkbox selection column -->
          <el-table-column type="selection" width="55" reserve-selection> </el-table-column>

          <!-- Name column -->
          <el-table-column
            prop="document_name"
            label="Name"
            sortable
            :sort-method="caseInsensitiveSort"
          >
            <template #default="{ row }">
              {{ row.document_name || row.title }}
            </template>
          </el-table-column>

          <!-- Created At column -->
          <el-table-column prop="createdAt" label="Created At" sortable width="200">
            <template #default="{ row }">
              {{ formatDate(row.createdAt) }}
            </template>
          </el-table-column>
        </el-table>

        <el-input
          :value="keyExport.k"
          placeholder="Generated Password"
          type="text"
          disabled
          style="margin-bottom: 20px"
        >
          <template #append>
            <el-button
              type="primary"
              @click="copyKey(keyExport)"
              :loading="isLoading"
              :disabled="isLoading"
            >
              Copy
            </el-button>
          </template>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" :loading="isLoading" :disabled="isLoading"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          @click="handleExport"
          :loading="isLoading"
          :disabled="isLoading || selectedDocuments.length === 0"
        >
          Export ({{ selectedDocuments.length }})
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "ExportDialog",
  props: ["isLoading", "dialogVisible", "documents", "exportType"],
  data() {
    return {
      selectedDocuments: [],
      search: "",
      areAllSelected: false,
      keyExport: "",
      algo: {
        name: "AES-GCM",
        iv: new TextEncoder("utf-8").encode("keyreply"),
      },
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
    async handleExport() {
      const selectedDocuments = this.documents.filter((document) =>
        this.selectedDocuments.includes(document.id)
      );
      let exportData;
      switch (this.exportType) {
        case "documents":
          exportData = await this.$store.dispatch("GENAI_EXPORT_DOCUMENTS", {
            documents: selectedDocuments,
          });
          break;
        default:
          exportData = selectedDocuments;
          break;
      }
      const { key, cipherBuffer } = await this.encrypt(
        JSON.stringify({
          data: exportData,
          exportType: this.exportType,
        })
      );
      this.keyExport = await window.crypto.subtle.exportKey("jwk", key);
      this.copyKey(this.keyExport);
      this.downloadFile(cipherBuffer);
    },
    updateExportDialogVisible(value) {
      this.$emit("updateDialogVisible", value);
    },
    toggleSelectAll() {
      switch (this.exportType) {
        case "document":
          this.selectedDocuments =
            this.selectedDocuments.length === this.documents.length
              ? []
              : this.documents.map((document) => document.document_id);
          this.areAllSelected = !this.areAllSelected;
          break;
        default:
          this.selectedDocuments =
            this.selectedDocuments.length === this.documents.length
              ? []
              : this.documents.map((document) => document.id);
          this.areAllSelected = !this.areAllSelected;
          break;
      }
    },
    async encrypt(plaintext) {
      console.log(plaintext);
      const textEncoder = new TextEncoder("utf-8");
      const algoKeyGen = {
        name: "AES-GCM",
        length: 256,
      };
      const key = await window.crypto.subtle.generateKey(algoKeyGen, true, ["encrypt"]);
      const data = textEncoder.encode(plaintext);
      const cipherBuffer = await crypto.subtle.encrypt(this.algo, key, data);
      this.keyExport = await crypto.subtle.exportKey("jwk", key);
      return { key, cipherBuffer };
    },
    copyKey(keyExportObj) {
      if (keyExportObj) {
        const clip = document.createElement("input");
        document.body.appendChild(clip);
        clip.setAttribute("value", keyExportObj.k);
        clip.select();
        document.execCommand("copy");
        document.body.removeChild(clip);

        this.$message({
          message: "Successfully copied export password to the clipboard.",
          type: "success",
        });
      } else {
        this.$message({
          message: "No key detected. Please export again.",
          type: "error",
        });
      }
    },
    downloadFile(cipherBuffer) {
      const blob = new Blob([cipherBuffer], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const timestamp = moment().format("YYYYMMDD_HHmm");
      a.href = url;
      a.download = `${this.exportType}_export_${timestamp}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
    handleSelectionChange(selectedRows) {
      this.selectedDocuments = selectedRows.map((row) => row.id);
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    caseInsensitiveSort(a, b) {
      const nameA = (a.document_name || a.title || "").toLowerCase();
      const nameB = (b.document_name || b.title || "").toLowerCase();
      return nameA.localeCompare(nameB);
    },
  },
  computed: {
    filterDocuments() {
      const sortedDocuments = [...this.documents];
      switch (this.exportType) {
        case "documents":
          return sortedDocuments.filter((document) =>
            document.document_name.toLowerCase().includes(this.search.toLowerCase())
          );
        case "articles":
          return sortedDocuments.filter((article) =>
            article.title.toLowerCase().includes(this.search.toLowerCase())
          );
        case "websites":
          return sortedDocuments.filter((website) =>
            website.title.toLowerCase().includes(this.search.toLowerCase())
          );
        default:
          return [];
      }
    },
  },
  created() {
    // Generate encrypt password
  },
};
</script>
<style></style>
